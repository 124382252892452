import React, { useState } from 'react';
import {
    Button,
    Grid,
    Typography,
} from '@mui/material';

import TableAModal from './Components/TableAModal'
import TableBModal from './Components/TableBModal'
import TableCModal from './Components/TableCModal'

export const Calculator = () => {

    const [m11, setm11] = useState('');
    const [m12, setm12] = useState('');
    const [m21, setm21] = useState('');
    const [m22, setm22] = useState('');
    const [qc11, setqc11] = useState('');
    const [qc12, setqc12] = useState('');
    const [qc21, setqc21] = useState('');
    const [qc22, setqc22] = useState('');
    const [qc31, setqc31] = useState('');
    const [qc32, setqc32] = useState('');
    const [qa11, setqa11] = useState('');
    const [qa12, setqa12] = useState('');
    const [qa21, setqa21] = useState('');
    const [qa22, setqa22] = useState('');
    const [qa31, setqa31] = useState('');
    const [qa32, setqa32] = useState('');
    const [qa41, setqa41] = useState('');
    const [qa42, setqa42] = useState('');

    const [tableAModal, setTableAModal] = useState(false);
    const [tableBModal, setTableBModal] = useState(false);
    const [tableCModal, setTableCModal] = useState(false);

    const [lightColor, setLightColor] = useState(null);
    const [prodColor, setProdColor] = useState(null);
    const [conColor, setConColor] = useState(null);
    const [assColor, setAssColor] = useState(null);

    let m1Total = Number(m12) / Number(m11) * 100;
    let m2Total = Number(m22) / Number(m21) * 100;
    let qc1Total = Number(qc12) / Number(qc11) * 100;
    let qc2Total = Number(qc22) / Number(qc21) * 100;
    let qc3Total = Number(qc32) / Number(qc31) * 100;
    let qa1Total = Number(qa12) / Number(qa11) * 100;
    let qa2Total = Number(qa22) / Number(qa21) * 100;
    let qa3Total = Number(qa32) / Number(qa31) * 100;
    let qa4Total = Number(qa42) / Number(qa41) * 100;


    let FMEA = [];

    FMEA[0] = m2Total * ((qc2Total + qc3Total) / 2) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[1] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[2] = ((m1Total + m2Total) / 2) * ((qc1Total + qc3Total) / 2) * ((qa1Total + qa3Total + qa4Total) / 3);
    FMEA[3] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa3Total + qa4Total) / 3);
    FMEA[4] = ((m1Total + m2Total) / 2) * ((qc1Total + qc3Total) / 2) * ((qa1Total + qa3Total + qa4Total) / 3);
    FMEA[5] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[6] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa3Total + qa4Total) / 3);
    FMEA[7] = m2Total * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[8] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa3Total + qa4Total) / 3);
    FMEA[9] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[10] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[11] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[12] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[13] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);
    FMEA[14] = ((m1Total + m2Total) / 2) * ((qc1Total + qc2Total + qc3Total) / 3) * ((qa1Total + qa2Total + qa3Total + qa4Total) / 4);

    let FMEATotal = FMEA.reduce((a, b) => a + b, 0);

    let percentFactor = [];
    FMEA.forEach((value, index) => {
        percentFactor[index] = value / (FMEATotal / 100);
    });

    let percentFactorTotal = percentFactor.reduce((a, b) => a + b, 0);

    let prodVal = [];
    prodVal[0] = (m2Total) / 1 * 3 * percentFactor[0];
    prodVal[1] = (m1Total + m2Total) / 2 * 3 * percentFactor[1];
    prodVal[2] = (m1Total + m2Total) / 2 * 2 * percentFactor[2];
    prodVal[3] = (m1Total + m2Total) / 2 * 2 * percentFactor[3];
    prodVal[4] = (m1Total + m2Total) / 2 * 2 * percentFactor[4];
    prodVal[5] = (m1Total + m2Total) / 2 * 2 * percentFactor[5];
    prodVal[6] = (m1Total + m2Total) / 2 * 2 * percentFactor[6];
    prodVal[7] = (m2Total) / 1 * 2 * percentFactor[7];
    prodVal[8] = (m1Total + m2Total) / 2 * 3 * percentFactor[8];
    prodVal[9] = (m1Total + m2Total) / 2 * 3 * percentFactor[9];
    prodVal[10] = (m1Total + m2Total) / 2 * 2 * percentFactor[10];
    prodVal[11] = (m1Total + m2Total) / 2 * 2 * percentFactor[11];
    prodVal[12] = (m1Total + m2Total) / 2 * 2 * percentFactor[12];
    prodVal[13] = (m1Total + m2Total) / 2 * 3 * percentFactor[13];
    prodVal[14] = (m1Total + m2Total) / 2 * 2 * percentFactor[14];

    let prodTotal = Math.round((prodVal.reduce((a, b) => a + b, 0)) / 15);

    let conVal = [];
    conVal[0] = (qc2Total + qc3Total) / 2 * 2 * percentFactor[0];
    conVal[1] = (qc1Total + qc2Total + qc3Total) / 3 * 3 * percentFactor[1];
    conVal[2] = (qc1Total + qc3Total) / 2 * 3 * percentFactor[2];
    conVal[3] = (qc1Total + qc2Total + qc3Total) / 3 * 3 * percentFactor[3];
    conVal[4] = (qc1Total + qc3Total) / 2 * 2 * percentFactor[4];
    conVal[5] = (qc1Total + qc2Total + qc3Total) / 3 * 3 * percentFactor[5];
    conVal[6] = (qc1Total + qc2Total + qc3Total) / 3 * 3 * percentFactor[6];
    conVal[7] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[7];
    conVal[8] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[8];
    conVal[9] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[9];
    conVal[10] = (qc1Total + qc2Total + qc3Total) / 3 * 3 * percentFactor[10];
    conVal[11] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[11];
    conVal[12] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[12];
    conVal[13] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[13];
    conVal[14] = (qc1Total + qc2Total + qc3Total) / 3 * 2 * percentFactor[14];

    let conTotal = Math.round((conVal.reduce((a, b) => a + b, 0)) / 15);

    let assVal = [];
    assVal[0] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[0];
    assVal[1] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[1];
    assVal[2] = (qa1Total + qa3Total + qa4Total) / 3 * 2 * percentFactor[2];
    assVal[3] = (qa1Total + qa3Total + qa4Total) / 3 * 3 * percentFactor[3];
    assVal[4] = (qa1Total + qa3Total + qa4Total) / 3 * 2 * percentFactor[4];
    assVal[5] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[5];
    assVal[6] = (qa1Total + qa3Total + qa4Total) / 3 * 2 * percentFactor[6];
    assVal[7] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[7];
    assVal[8] = (qa1Total + qa3Total + qa4Total) / 3 * 1 * percentFactor[8];
    assVal[9] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[9];
    assVal[10] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[10];
    assVal[11] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 1 * percentFactor[11];
    assVal[12] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 1 * percentFactor[12];
    assVal[13] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 2 * percentFactor[13];
    assVal[14] = (qa1Total + qa2Total + qa3Total + qa4Total) / 4 * 1 * percentFactor[14];

    let assTotal = Math.round((assVal.reduce((a, b) => a + b, 0)) / 15);


    let qmmTotal = Math.round((assTotal + conTotal + prodTotal) / 3)

    const saveTableA = (val) => {
        setm11(val.m11)
        setm12(val.m12)
        setm21(val.m21)
        setm22(val.m22)
        setTableAModal(false)
    }

    const saveTableB = (val) => {
        setqc11(val.qc11)
        setqc12(val.qc12)
        setqc21(val.qc21)
        setqc22(val.qc22)
        setqc31(val.qc31)
        setqc32(val.qc32)

        setTableBModal(false)
    }

    const saveTableC = (val) => {
        setqa11(val.qa11)
        setqa12(val.qa12)
        setqa21(val.qa21)
        setqa22(val.qa22)
        setqa31(val.qa31)
        setqa32(val.qa32)
        setqa41(val.qa41)
        setqa42(val.qa42)
        setTableCModal(false)
    }

    const goCalculate = () => {
        if ((m11 && m11 !== '') && (qc11 && qc11 !== '') && (qa11 && qa11 !== '')) {
            if (qmmTotal <= 100) {
                setLightColor('green')
            } else if (qmmTotal > 200) {
                setLightColor('red')
            } else {
                setLightColor('orange')
            }

            if (prodTotal <= 100) {
                setProdColor('green')
            } else if (prodTotal > 200) {
                setProdColor('red')
            } else {
                setProdColor('orange')
            }

            if (conTotal <= 100) {
                setConColor('green')
            } else if (conTotal > 200) {
                setConColor('red')
            } else {
                setConColor('orange')
            }

            if (assTotal <= 100) {
                setAssColor('green')
            } else if (assTotal > 200) {
                setAssColor('red')
            } else {
                setAssColor('orange')
            }

        }
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            spacing={1}
            sx={{ padding: 5, minHeight: 620 }}
        >
            <Grid item>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="Center"
                    sx={{ padding: 2, minHeight: 550, borderStyle: 'solid', borderWidth: 5, borderColor: '#4d4d4d', borderRadius: 10 }}
                >
                    <Typography variant="h5" width={300}>
                        Performance Data: Medicinal Product Manufacture
                    </Typography>
                    <div style={(m11 && m11 !== '') ? styles.buttonSuccess : styles.buttonDefault}>
                        <Button
                            variant="contained"
                            onClick={() => setTableAModal(true)}
                            sx={{ width: 250, height: 100 }}
                        >
                            Table I A: Production Performance Metrics (Control of Occurence)
                        </Button>
                    </div>
                    <div style={(qc11 && qc11 !== '') ? styles.buttonSuccess : styles.buttonDefault}>
                        <Button
                            variant="contained"
                            onClick={() => setTableBModal(true)}
                            sx={{ width: 250, height: 100 }}
                        >
                            Table I B: Quality Control Performance Metrics (Control of Severity)
                        </Button>
                    </div>
                    <div style={(qa11 && qa11 !== '') ? styles.buttonSuccess : styles.buttonDefault}>
                        <Button
                            variant="contained"
                            onClick={() => setTableCModal(true)}
                            sx={{ width: 250, height: 100 }}
                        >
                            Table I C:Quality Assurance Performance Metrics (Control of Probability)
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ padding: 5, minHeight: 550 }}
                >
                    <Button
                        variant="outlined"
                        size="medium"
                        color={((m11 && m11 !== '') && (qc11 && qc11 !== '') && (qa11 && qa11 !== '')) ? 'success' : 'error'}
                        onClick={() => goCalculate()}
                        sx={{
                            width: 25,
                            height: 25,
                            padding: 10,
                            borderRadius: 100,
                            fontWeight: 'bold',
                            border: '2px solid'
                        }}
                    >
                        Go Calculate
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="Center"
                    sx={{ padding: 2, minHeight: 550, borderStyle: 'solid', borderWidth: 5, borderColor: '#4d4d4d', borderRadius: 10 }}
                >
                    <Typography variant="h5" width={300}>
                        QUALITY MANAGEMENT MATURITY (QMM)
                    </Typography>
                    <div style={{...styles.borderedText,backgroundColor:prodColor}} >
                        <Typography variant="h7">
                            PRODUCTION
                        </Typography>
                    </div>
                    <div style={{...styles.borderedText,backgroundColor:conColor}} >
                        <Typography variant="h7">
                            QUALITY CONTROL
                        </Typography>
                    </div>
                    <div style={{...styles.borderedText,backgroundColor:assColor}} >
                        <Typography variant="h7">
                            QUALITY ASSURANCE
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ borderStyle: 'solid', borderWidth: 5, borderRadius: 10, borderColor: 'black', backgroundColor: 'black', height: 550, width: 250, }}
                >
                    <div style={{ borderStyle: 'solid', borderWidth: 5, borderRadius: 100, borderColor: '#4d4d4d', height: 150, width: 150, backgroundColor: lightColor === 'red' ? 'red' : '#4d4d4d' }} />
                    <div style={{ borderStyle: 'solid', borderWidth: 5, borderRadius: 100, borderColor: '#4d4d4d', height: 150, width: 150, backgroundColor: lightColor === 'orange' ? 'orange' : '#4d4d4d' }} />
                    <div style={{ borderStyle: 'solid', borderWidth: 5, borderRadius: 100, borderColor: '#4d4d4d', height: 150, width: 150, backgroundColor: lightColor === 'green' ? 'green' : '#4d4d4d'  }} />
                    
                </Grid>
            </Grid>
            {/*Table A Modal*/}
            {tableAModal &&
                <TableAModal
                    open={tableAModal}
                    onClose={() => setTableAModal(false)}
                    onSave={saveTableA}
                    data={{ m11, m12, m21, m22 }}
                />
            }
            {/*Table B Modal*/}
            {tableBModal &&
                <TableBModal
                    open={tableBModal}
                    onClose={() => setTableBModal(false)}
                    onSave={saveTableB}
                    data={{ qc11, qc12, qc21, qc22, qc31, qc32 }}
                />
            }
            {/*Table C Modal*/}
            {tableCModal &&
                <TableCModal
                    open={tableCModal}
                    onClose={() => setTableCModal(false)}
                    onSave={saveTableC}
                    data={{ qa11, qa12, qa21, qa22, qa31, qa32, qa41, qa42 }}
                />
            }
        </Grid>
    )

}

const styles = {
    buttonDefault: {
        padding: 10
    },
    buttonSuccess: {
        padding: 5,
        borderColor: '#04AA6D',
        borderStyle: 'solid',
        borderWidth: 5,
    },
    borderedText: {
        width: 200,
        height: 100,
        borderStyle: 'solid',
        borderWidth: 5,
        borderRadius: 10,
        borderColor: '#4d4d4d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}


export default Calculator;