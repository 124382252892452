import './App.css';
import { Route, Routes } from "react-router-dom";
import LoginPage from './Container/Login';
import CalculatorPage from './Container/Calculator/index';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<LoginPage />} />
        <Route path="/calculator" element={<CalculatorPage />} /> */}
        <Route path="/" element={<CalculatorPage />} />
      </Routes>
    </div>
  );
}

export default App;
