import React, { useState } from 'react';
import {
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';


export const TableAModal = (props) => {

    const [m11, setm11] = useState(props.data.m11);
    const [m12, setm12] = useState(props.data.m12);
    const [m21, setm21] = useState(props.data.m21);
    const [m22, setm22] = useState(props.data.m22);

    let m1Total = Number(m12) / Number(m11) * 100;
    let m2Total = Number(m22) / Number(m21) * 100;

    const [m11Error, setm11Error] = useState(false);
    const [m12Error, setm12Error] = useState(false);
    const [m21Error, setm21Error] = useState(false);
    const [m22Error, setm22Error] = useState(false);

    const save = () => {
        let noError = true
        if (!m11 || m11 === '') {
            noError = false
            setm11Error(true)
        }

        if (!m12 || m12 === '') {
            noError = false
            setm12Error(true)
        }

        if (!m21 || m21 === '') {
            noError = false
            setm21Error(true)
        }

        if (!m22 || m22 === '') {
            noError = false
            setm22Error(true)
        }

        if (noError) {
            props.onSave({m11, m12, m21, m22})
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogTitle>Table I A: Production Performance Metrics (Control of Occurence) </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">PRODUCTION</TableCell>
                            <TableCell align="center">Metric: Identification of significant incidents</TableCell>
                            <TableCell align="center">Alert & Action Limits</TableCell>
                            <TableCell align="center">Batches manufactured</TableCell>
                            <TableCell align="center">Batches with Significant Incidents</TableCell>
                            <TableCell align="center">% Affected</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Batch Expected Yield and In-time Supply</TableCell>
                            <TableCell align="center">% deviating yield</TableCell>
                            <TableCell align="center">(1,0 / 5,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={m11Error}
                                    value={m11}
                                    onChange={(e) => {
                                        setm11(e.target.value)
                                        setm11Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={m12Error}
                                    value={m12}
                                    onChange={(e) => {
                                        setm12(e.target.value)
                                        setm12Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(m1Total) ? "" : m1Total.toFixed(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Batch left First Time: In-Process Robustness</TableCell>
                            <TableCell align="center">% of incidents</TableCell>
                            <TableCell align="center">(1,0 / 5,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={m21Error}
                                    value={m21}
                                    onChange={(e) => {
                                        setm21(e.target.value)
                                        setm21Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={m22Error}
                                    value={m22}
                                    onChange={(e) => {
                                        setm22(e.target.value)
                                        setm22Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(m2Total) ? "" : m2Total.toFixed(1)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={save} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TableAModal;