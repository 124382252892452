import React, { useState } from 'react';
import {
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';


export const TableCModal = (props) => {

    const [qa11, setqa11] = useState(props.data.qa11);
    const [qa12, setqa12] = useState(props.data.qa12);
    const [qa21, setqa21] = useState(props.data.qa21);
    const [qa22, setqa22] = useState(props.data.qa22);
    const [qa31, setqa31] = useState(props.data.qa31);
    const [qa32, setqa32] = useState(props.data.qa32);
    const [qa41, setqa41] = useState(props.data.qa41);
    const [qa42, setqa42] = useState(props.data.qa42);

    let qa1Total = Number(qa12) / Number(qa11) * 100;
    let qa2Total = Number(qa22) / Number(qa21) * 100;
    let qa3Total = Number(qa32) / Number(qa31) * 100;
    let qa4Total = Number(qa42) / Number(qa41) * 100;

    const [qa11Error, setqa11Error] = useState(false);
    const [qa12Error, setqa12Error] = useState(false);
    const [qa21Error, setqa21Error] = useState(false);
    const [qa22Error, setqa22Error] = useState(false);
    const [qa31Error, setqa31Error] = useState(false);
    const [qa32Error, setqa32Error] = useState(false);
    const [qa41Error, setqa41Error] = useState(false);
    const [qa42Error, setqa42Error] = useState(false);

    const save = () => {
        let noError = true
        if (!qa11 || qa11 === '') {
            noError = false
            setqa11Error(true)
        }

        if (!qa12 || qa12 === '') {
            noError = false
            setqa12Error(true)
        }

        if (!qa21 || qa21 === '') {
            noError = false
            setqa21Error(true)
        }

        if (!qa22 || qa22 === '') {
            noError = false
            setqa22Error(true)
        }

        if (!qa31 || qa31 === '') {
            noError = false
            setqa31Error(true)
        }

        if (!qa32 || qa32 === '') {
            noError = false
            setqa32Error(true)
        }

        if (!qa41 || qa41 === '') {
            noError = false
            setqa41Error(true)
        }

        if (!qa42 || qa42 === '') {
            noError = false
            setqa42Error(true)
        }

        if (noError) {
            props.onSave({ qa11, qa12, qa21, qa22, qa31, qa32, qa41, qa42 })
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogTitle>Table I C:Quality Assurance Performance Metrics (Control of Probability)</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">QUALITY ASSURANCE</TableCell>
                            <TableCell align="center">Metric: Identification of significant incidents</TableCell>
                            <TableCell align="center">Alert & Action Limits</TableCell>
                            <TableCell align="center">Batches manufactured</TableCell>
                            <TableCell align="center">Batches with Significant Incidents</TableCell>
                            <TableCell align="center">% Affected</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Quality Systems</TableCell>
                            <TableCell align="center">% Not In-time Document Revision</TableCell>
                            <TableCell align="center">(5,0 / 10,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa11Error}
                                    value={qa11}
                                    onChange={(e) => {
                                        setqa11(e.target.value)
                                        setqa11Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa12Error}
                                    value={qa12}
                                    onChange={(e) => {
                                        setqa12(e.target.value)
                                        setqa12Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qa1Total) ? "" : qa1Total.toFixed(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={2} align="left">Internal Audits</TableCell>
                            <TableCell align="center">% Audits deviation to Plan</TableCell>
                            <TableCell align="center">(10,0 / 20,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa21Error}
                                    value={qa21}
                                    onChange={(e) => {
                                        setqa21(e.target.value)
                                        setqa21Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa22Error}
                                    value={qa22}
                                    onChange={(e) => {
                                        setqa22(e.target.value)
                                        setqa22Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qa2Total) ? "" : qa2Total.toFixed(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center"> # Serious and Major Observations Follow-up</TableCell>
                            <TableCell align="center">(10,0 / 20,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa31Error}
                                    value={qa31}
                                    onChange={(e) => {
                                        setqa31(e.target.value)
                                        setqa31Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa32Error}
                                    value={qa32}
                                    onChange={(e) => {
                                        setqa32(e.target.value)
                                        setqa32Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qa3Total) ? "" : qa3Total.toFixed(1)}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="center">Metric: Identification of significant incidents</TableCell>
                            <TableCell align="center">Alert & Action Limits</TableCell>
                            <TableCell align="center"># Lots delivered</TableCell>
                            <TableCell align="center"># QA Action Results</TableCell>
                            <TableCell align="center">% Affected</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Product Surveillance: Post Delivery Feedback and Complaints</TableCell>
                            <TableCell align="center">% Impacted Feedback # batches delivered</TableCell>
                            <TableCell align="center">(1,0 /3,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa41Error}
                                    value={qa41}
                                    onChange={(e) => {
                                        setqa41(e.target.value)
                                        setqa41Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qa42Error}
                                    value={qa42}
                                    onChange={(e) => {
                                        setqa42(e.target.value)
                                        setqa42Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qa4Total) ? "" : qa4Total.toFixed(1)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={save} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TableCModal;