import React, { useState } from 'react';
import {
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';


export const TableBModal = (props) => {

    const [qc11, setqc11] = useState(props.data.qc11);
    const [qc12, setqc12] = useState(props.data.qc12);
    const [qc21, setqc21] = useState(props.data.qc21);
    const [qc22, setqc22] = useState(props.data.qc22);
    const [qc31, setqc31] = useState(props.data.qc31);
    const [qc32, setqc32] = useState(props.data.qc32);

    let qc1Total = Number(qc12) / Number(qc11) * 100;
    let qc2Total = Number(qc22) / Number(qc21) * 100;
    let qc3Total = Number(qc32) / Number(qc31) * 100;

    const [qc11Error, setqc11Error] = useState(false);
    const [qc12Error, setqc12Error] = useState(false);
    const [qc21Error, setqc21Error] = useState(false);
    const [qc22Error, setqc22Error] = useState(false);
    const [qc31Error, setqc31Error] = useState(false);
    const [qc32Error, setqc32Error] = useState(false);

    const save = () => {
        let noError = true
        if (!qc11 || qc11 === '') {
            noError = false
            setqc11Error(true)
        }

        if (!qc12 || qc12 === '') {
            noError = false
            setqc12Error(true)
        }

        if (!qc21 || qc21 === '') {
            noError = false
            setqc21Error(true)
        }

        if (!qc22 || qc22 === '') {
            noError = false
            setqc22Error(true)
        }

        if (!qc31 || qc31 === '') {
            noError = false
            setqc31Error(true)
        }

        if (!qc32 || qc32 === '') {
            noError = false
            setqc32Error(true)
        }

        if (noError) {
            props.onSave({qc11, qc12, qc21, qc22, qc31, qc32})
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogTitle>Table I B: Quality Control Performance Metrics (Control of Severity)</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">QUALITY CONTROL</TableCell>
                            <TableCell align="center">Metric: Identification of significant incidents</TableCell>
                            <TableCell align="center">Alert & Action Limits</TableCell>
                            <TableCell align="center">Batches manufactured</TableCell>
                            <TableCell align="center">Batches with Significant Incidents</TableCell>
                            <TableCell align="center">% Affected</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Analytical Methods and Controls: Equipment and Method Suitability</TableCell>
                            <TableCell align="center">% Quarantined Equipment</TableCell>
                            <TableCell align="center">(10,0 / 15,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qc11Error}
                                    value={qc11}
                                    onChange={(e) => {
                                        setqc11(e.target.value)
                                        setqc11Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qc12Error}
                                    value={qc12}
                                    onChange={(e) => {
                                        setqc12(e.target.value)
                                        setqc12Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qc1Total) ? "" : qc1Total.toFixed(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Batch Release Process: Transfer Batch Documentation</TableCell>
                            <TableCell align="center">% Exceeding Batch Release</TableCell>
                            <TableCell align="center">(10 / 15 Days) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qc21Error}
                                    value={qc21}
                                    onChange={(e) => {
                                        setqc21(e.target.value)
                                        setqc21Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qc22Error}
                                    value={qc22}
                                    onChange={(e) => {
                                        setqc22(e.target.value)
                                        setqc22Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qc2Total) ? "" : qc2Total.toFixed(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">CAPA Action Plan: Deviations /Rejects /OOS</TableCell>
                            <TableCell align="center">% CAPA plan In-time</TableCell>
                            <TableCell align="center">(0,5 / 2,0) %</TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qc31Error}
                                    value={qc31}
                                    onChange={(e) => {
                                        setqc31(e.target.value)
                                        setqc31Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    error={qc32Error}
                                    value={qc32}
                                    onChange={(e) => {
                                        setqc32(e.target.value)
                                        setqc32Error(false)
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">{isNaN(qc3Total) ? "" : qc3Total.toFixed(1)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={save} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TableBModal;